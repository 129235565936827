import {
	fetchUtils,
	GET_LIST,
	GET_ONE,
	GET_MANY,
	GET_MANY_REFERENCE,
	CREATE,
	UPDATE,
	UPDATE_MANY,
	DELETE,
	DELETE_MANY,
} from "react-admin";
import qs from "qs";

/**
 * Maps react-admin queries to a simple REST API
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
const strapiProvider = (
	apiUrl,
	httpClient = fetchUtils.fetchJson,
	uploadFields = []
) => {
	/**
	 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
	 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
	 * @param {Object} params The data request params, depending on the type
	 * @returns {Object} { url, options } The HTTP request parameters
	 */
	const convertDataRequestToHTTP = (type, resource, params) => {
		let url = "";
		const options = {};
		switch (type) {
			case GET_LIST:
			case GET_MANY_REFERENCE:
				url = `${apiUrl}/${resource}?${adjustQueryForStrapi(params)}`;
				break;
			case GET_ONE:
				url = `${apiUrl}/${resource}/${params.id}?${adjustQueryForStrapi(
					params
				)}`;
				break;
			case UPDATE:
				url = `${apiUrl}/${resource}/${params.id}?populate=*`;
				options.method = "PUT";
				// Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
				// const {created_at, updated_at, createdAt, updatedAt, ...data} = params.data;
				// Note: we may need to reinstate the above, but it seemed to cause issues when using useUpdate() hook
				// Relation fields need to be in the form relationshipName: [id1, id2, id3] not relationshipName: [{id: id1}, {id: id2}, {id: id3}]
				// This is because the API will not accept the latter

				const data = Object.keys(params.data).reduce((acc, key) => {
					//This is so dumb, but it works
					if (
						params.data[key] &&
						typeof params.data[key] === "object" &&
						params.data[key] !== null &&
						"data" in params.data[key]
					) {
						if (params.data[key].data && params.data[key].data.id) {
							acc[key] = params.data[key].data.id;
						} else if (
							params.data[key].data &&
							Array.isArray(params.data[key].data)
						) {
							acc[key] = params.data[key].data;
						}
					} else {
						acc[key] = params.data[key];
					}
					return acc;
				}, {});

				options.body = JSON.stringify({ data: data, id: params.id });
				break;
			case CREATE:
				url = `${apiUrl}/${resource}`;
				options.method = "POST";
				options.body = JSON.stringify(params);
				break;
			case DELETE:
				url = `${apiUrl}/${resource}/${params.id}`;
				options.method = "DELETE";
				break;
			default:
				throw new Error(`Unsupported fetch action type ${type}`);
		}
		return { url, options };
	};

	const adjustQueryForStrapi = (params) => {
		/*
      params = { 
          pagination: { page: {int} , perPage: {int} }, 
          sort: { field: {string}, order: {string} }, 
          filter: {Object}, 
          target: {string}, (REFERENCE ONLY)
          id: {mixed} (REFERENCE ONLY)
      }
      */

		const query = {};

		// Handle SORTING
		const s = params.sort;
		query.sort = [(s?.field ?? "updatedAt") + ":" + (s?.order ?? "DESC")];

		// Handle FILTER
		const f = params.filter;
		if (f && Object.keys(f).length) {
			query.filters = f;
			if (query.filters.q) {
				query._q = query.filters.q;
				delete query.filters.q;
			}
		}

		//handle REFERENCES
		const t = params.target;
		if (t) {
			query.filters = query.filters ?? {};
			query.filters[t] = {
				id: {
					$eq: params.id,
				},
			};
		}

		//handle META
		const m = params.meta;
		if (m) {
			query.meta = m;
		}

		// Handle PAGINATION
		const { page, perPage } = params.pagination ?? {};
		query.pagination = {
			page: page ?? 1,
			pageSize: perPage ?? 10,
		};

		// Populate relationshionships to one level of depth
		query.populate = "*";

		return qs.stringify(query);
	};

	// Determines if there are new files to upload
	// and returns file names in array if there are
	const determineUploadFieldNames = (params) => {
		if (!params.data) return [];

		// Check if the field names are mentioned in the uploadFields
		// and verify there are new files being added
		const requestUplaodFieldNames = [];
		Object.keys(params.data).forEach((field) => {
			let fieldData = params.data[field];
			if (uploadFields.includes(field)) {
				fieldData = !Array.isArray(fieldData) ? [fieldData] : fieldData;
				fieldData.filter((f) => f && f.rawFile instanceof File).length > 0 &&
					requestUplaodFieldNames.push(field);
			}
		});

		// Return an array of field names where new files are added
		return requestUplaodFieldNames;
	};

	// Handles file uploading for CREATE and UPDATE types
	const handleFileUpload = (type, resource, params, uploadFieldNames) => {
		const { created_at, updated_at, createdAt, updatedAt, ...data } =
			params.data;
		const id = type === UPDATE ? `/${params.id}` : "";
		const url = `${apiUrl}/${resource}${id}`;
		const requestMethod = type === UPDATE ? "PUT" : "POST";
		const formData = new FormData();

		for (let fieldName of uploadFieldNames) {
			let fieldData = params.data[fieldName];
			fieldData = !Array.isArray(fieldData) ? [fieldData] : fieldData;
			const existingFileIds = [];

			for (let item of fieldData) {
				item.rawFile instanceof File
					? formData.append(`files.${fieldName}`, item.rawFile)
					: existingFileIds.push(item.id || item._id);
			}

			data[fieldName] = [...existingFileIds];
		}
		formData.append("data", JSON.stringify(data));

		return httpClient(url, {
			method: requestMethod,
			body: formData,
		}).then((response) => ({ data: replaceRefObjectsWithIds(response.json) }));
	};

	// Replace reference objects with reference object IDs
	const replaceRefObjectsWithIds = (json) => {
		Object.keys(json).forEach((key) => {
			let fd = json[key]; // field data
			const referenceKeys = [];
			if (fd && typeof fd === "object") {
				const data = fd.data;
				if (typeof data === "object" && data !== null) {
					if (data.map) {
						data.map((item) => referenceKeys.push(item.id || item._id));
						json[key] = referenceKeys;
					} else {
						json[key] = data.id || data._id;
					}
				}
			}
		});
		return json;
	};

	/**
	 * @param {Object} response HTTP response from fetch()
	 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
	 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
	 * @param {Object} params The data request params, depending on the type
	 * @returns {Object} Data response
	 */
	const convertHTTPResponse = (response, type, resource, params) => {
		const { json, total } = response;
		switch (type) {
			case GET_ONE:
				const attributes = params.meta?.returnRecords
					? json.data.attributes
					: replaceRefObjectsWithIds(json.data.attributes);
				return { data: { ...attributes, id: json.data.id } };
			case GET_LIST:
			case GET_MANY_REFERENCE:
				return {
					data: json.data.map((item) => {
						return { ...item.attributes, id: item.id };
					}),
					total,
				};
			case UPDATE:
			case CREATE:
				return { data: { ...params.data, id: json.id } };
			case DELETE:
				return { data: { id: null } };
			default:
				return { data: json };
		}
	};

	/**
	 * @param {string} type Request type, e.g GET_LIST
	 * @param {string} resource Resource name, e.g. "posts"
	 * @param {Object} payload Request parameters. Depends on the request type
	 * @returns {Promise} the Promise for a data response
	 */
	return (type, resource, params) => {
		// console.log(type, resource, params);

		//Strapi returns [{data: null}] when a relation field is empty, this causes issues with get_many especially for reference fields
		if (
			type === GET_MANY &&
			(params.ids.length === 0 || params.ids[0].data === null)
		)
			return Promise.resolve({ data: [] });

		//Sometimes for single records the id comes through as null or as id:{ data:{id:1}} so we need to extract it
		if (type === GET_ONE) {
			//If no ID, return null
			if (!params.id) return Promise.resolve({ data: {id:null} });

			if (typeof params.id === "object") {
				params.id = params.id.data.id;
				if (params.id === null) return Promise.resolve({ data: {id:null} });
			}
		}

		const uploadFieldNames = determineUploadFieldNames(params);
		if (uploadFieldNames.length > 0) {
			return handleFileUpload(type, resource, params, uploadFieldNames);
		}

		// simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
		if (type === UPDATE_MANY) {
			return Promise.all(
				params.ids.map((id) => {
					// Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
					const { created_at, updated_at, createdAt, updatedAt, ...data } =
						params.data;
					return httpClient(`${apiUrl}/${resource}/${id}`, {
						method: "PUT",
						body: JSON.stringify(data),
					});
				})
			).then((responses) => ({
				data: responses.map((response) => response.json),
			}));
		}
		// simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
		if (type === DELETE_MANY) {
			return Promise.all(
				params.ids.map((id) =>
					httpClient(`${apiUrl}/${resource}/${id}`, {
						method: "DELETE",
					})
				)
			).then((responses) => ({
				data: responses.map((response) => response.json),
			}));
		}
		//strapi doesn't handle filters in GET route
		if (type === GET_MANY) {
			return Promise.all(
				params.ids.map((i) =>
					httpClient(`${apiUrl}/${resource}/${i.id || i._id || i}?populate=*`, {
						method: "GET",
					})
				)
			).then((responses) => ({
				data: responses.map((response) => ({
					...response.json.data.attributes,
					id: response.json.data.id,
				})),
			}));
		}

		const { url, options } = convertDataRequestToHTTP(type, resource, params);

		// Get total via model/count endpoint
		if (type === GET_MANY_REFERENCE || type === GET_LIST) {
			return httpClient(url, options).then((response) => {
				response.total = response.json
					? response.json.meta.pagination.total
					: 0;
				return convertHTTPResponse(response, type, resource, params);
			});
		} else {
			return httpClient(url, options).then((response) =>
				convertHTTPResponse(response, type, resource, params)
			);
		}
	};
};

export default strapiProvider;

import * as React from "react";
import { Datagrid, NumberField, List, TextField, DateField, EditButton, Button, useRecordContext } from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';
import { Link } from 'react-router-dom';

const LinkToResults = () => {
    const record = useRecordContext();
    return record ? (
        <Button
            color="primary"
            component={Link}
            label="View results"
            to={{
                pathname: '/clients',
                search: `filter={"segment":${record.id}}`,
            }}
        ><PersonIcon /></Button>
    ) : null;
};

export const SegmentList = () => (
    <List>
        <Datagrid>
            <DateField source="createdAt" label="Created" />
            <DateField source="updatedAt" label="Updated" />
            <TextField source="name" label="Name" />
            {/* <NumberField source="results" label="Results" /> */}
            <LinkToResults />
            <EditButton />
        </Datagrid>
    </List>
)
export default SegmentList;
import Box from "@mui/material/Box";
import { SelectInput, useListContext, useGetList, Loading } from "react-admin";

const RequestService = ({ label, source }) => {
  const { displayedFilters, setFilters, filterValues } = useListContext();
	const { data, isLoading, error } = useGetList("service-groups", {
		pagination: { page: 1, perPage: 100 },
	});

	if ( isLoading || !data ) return <Loading />;
	if ( error ) return <p>ERROR</p>;

  //reset filter valuies if not displayed
  if( !displayedFilters[source] ){
    setFilters({...filterValues, [source]: undefined });
  };


	return (
		<SelectInput
			source="[service][service_group]"
			label="Service Group"
			choices={data}
		/>
	);
};

export default RequestService;

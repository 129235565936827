import {
	List,
	Datagrid,
	ReferenceField,
	TextField,
	DateField,
	useGetList,
	Loading,
	SelectInput,
	useUpdate,
	useRefresh,
	TopToolbar,
	CreateButton,
	ExportButton,
	FilterButton,
} from "react-admin";
import ClientNameField from "../../Fields/ClientName";
import RequestIndicator from "../../Components/RequestIndicator";
import RequestService from "../../Components/RequestService";
import RequestTimeframe from "../../Components/RequestTimeframe";
import OwnerIndicator from "../../Components/OwnerIndicator";

const RequestsTitle = () => {
	const hasReuests = window.location.href.includes("requests");
	return <span>{hasReuests ? "Demandes de service" : ""}</span>;
};

const RequestList = (props) => {
	const refresh = useRefresh();
	const { data, isLoading, error } = useGetList("service-groups", {
		pagination: { page: 1, perPage: 100 },
	});
	const [update, { updateLoading }] = useUpdate();

	const updateRequest = (id, updateData) => {
		update(
			"requests",
			{ id, data: updateData, previousData: data },
			{ mutationMode: "pessimistic", onSuccess: () => refresh() }
		);
	};

	if (isLoading || updateLoading || !data) {
		return <Loading />;
	}
	if (error) {
		return <p>ERROR</p>;
	}

	const requestFilters = [
		<RequestService source="service" label="Service" />,
		<RequestTimeframe source="requested" label="Requested" />,
		<RequestTimeframe source="referred" label="Referred" />,
		<RequestTimeframe source="acknowledged" label="Acknowledged" />,
		<RequestTimeframe source="received" label="Completed" />,
	];

	const RequestListActions = () => (
		<TopToolbar>
			<FilterButton />
			<CreateButton />
			<ExportButton maxResults={100000} />
		</TopToolbar>
	);

	return (
		<List
			{...props}
			filters={requestFilters}
			title={<RequestsTitle />}
			perPage={25}
			sort={{ field: "updatedAt", order: "DESC" }}
			actions={<RequestListActions />}
		>
			<Datagrid>
				<DateField source="updatedAt" label="Last Updated" showTime={true} />
				<ReferenceField
					label="Service Group"
					source="service.data.id"
					reference="services"
					sortBy="serviceGroup"
				>
					<TextField source="service_group.data.attributes.name" />
				</ReferenceField>
				<ReferenceField
					label="Service"
					source="service.data.id"
					reference="services"
					sortBy="service"
				>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					label="Client"
					source="client.data.id"
					reference="clients"
				>
					<ClientNameField source="last_name" />
				</ReferenceField>
				<RequestIndicator
					label="Requested"
					step="requested"
					updateRequest={updateRequest}
				/>
				<RequestIndicator
					label="Referred"
					step="referred"
					updateRequest={updateRequest}
				/>
				<RequestIndicator
					label="Acknowledged"
					step="acknowledged"
					updateRequest={updateRequest}
				/>
				<RequestIndicator
					label="Completed"
					step="received"
					updateRequest={updateRequest}
				/>
				<OwnerIndicator />
			</Datagrid>
		</List>
	);
};

export default RequestList;

import { Menu } from "react-admin";
import RuleIcon from "@mui/icons-material/Rule";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EmailIcon from "@mui/icons-material/Email";
import { useGetIdentity } from "react-admin";

const MenuItems = [
    {
        name: "Clients",
        icon: <PersonIcon />,
        to: "/clients",
        permissions: ['agent', 'hat','admin']
    },
    {
        name: "Requests",
        icon: <RuleIcon />,
        to: "/requests",
        permissions: ['agent', 'hat','admin']
    },
    {
        name: "Duplicates",
        icon: <PeopleIcon />,
        to: "/duplicates",
        permissions: ['agent', 'hat','admin']
    },
    {
        name: "Emails",
        icon: <EmailIcon />,
        to: "/emails",
        permissions: ['admin']
    },
    {
        name: "Segments",
        icon: <AssessmentIcon />,
        to: "/segments",
        permissions: ['admin']
    },
    {
        name: "Reports",
        icon: <AssessmentIcon />,
        to: "/reports",
        permissions: ['admin']
    },
];


export const CustomMenu = () => {
	const { data: identity, identityLoading } = useGetIdentity();
	if (identityLoading || !identity ) return null;
	return (
		<Menu>
			{MenuItems.map((item, index) => {
                if (item.permissions.includes(identity.role.type)) {
                    return (
                        <Menu.Item key={index} to={item.to} primaryText={item.name} leftIcon={item.icon}/>
                    );
                }else{
                    return null;
                }
            })}
		</Menu>
	);
};
